<template>
  <div class="loginView">
    <div class="nav-fixed">
      <van-nav-bar left-arrow @click-left="onClickLeft" :right-text="$t('选择语言')" @click-right="onClickRight"></van-nav-bar>
    </div>
    <div class="flool loginsignup2">
      <img class="logo_1" src="@/assets/images/bg_new_login-zh-CN.png" alt />
    </div>
    <div class="login_box text-center">
      <van-cell-group>
        <van-row class="mobile">
          <van-field left-icon="manager">
            <template #label>
              <van-dropdown-menu active-color="#ffffff">
                <van-dropdown-item
                  v-model="value1"
                  :title="dropTitle"
                  :options="option1"
                  @change="onChangeArea"
                />
              </van-dropdown-menu>
            </template>
            <template #input>
              <input v-model="mobile" :placeholder="$t('请输入手机号码')" class="van-field__control" />
            </template>
          </van-field>
        </van-row>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="password"
          type="password"
          left-icon="lock"
          right-icon="closed-eye"
          :placeholder="$t('请输入登录密码')"
        />
      </van-cell-group>
      <van-cell-group class="remember">
        <van-checkbox v-model="remember" checked-color="#4b34c3">{{ $t('记住用户名/密码') }}</van-checkbox>
      </van-cell-group>
      <van-button type="info" class="big_other_btn" @click="submitHandler">{{ $t('立即登录') }}</van-button>
      <div class="login_user_opreate">
        {{ $t('无like share账号?') }}
        <router-link to="/register" class="register">{{ $t('注册') }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from "@/utils/local-storage";

export default {
  name: "login",
  data() {
    return {
      remember: false,
      mobile: loadFromLocal("mobile"),
      password: loadFromLocal("password"),
      value1: 7,
      dropTitle: "+81",
      app_download_url: "",
      option1: [
        { text: "+66 "+this.$t('泰国'), value: 0 },
        { text: "+60 "+this.$t('马来西亚'), value: 1 },
        { text: "+91 "+this.$t('印度'), value: 2 },
        { text: "+86 "+this.$t('中国'), value: 3 },
        { text: "+63 "+this.$t('菲律宾'), value: 4 },
        { text: "+62 "+this.$t('印度尼西亚'), value: 5 },
        { text: "+65 "+this.$t('新加坡'), value: 6 },
        { text: "+81 "+this.$t('日本'), value: 7 },
        { text: "+82 "+this.$t('韩国'), value: 8 },
        { text: "+84 "+this.$t('越南'), value: 9 },
        { text: "+852 "+this.$t('香港'), value: 10 },
        { text: "+853 "+this.$t('澳门'), value: 11 },
        { text: "+855 "+this.$t('柬埔寨'), value: 12 },
        { text: "+856 "+this.$t('老挝'), value: 13 },
        { text: "+886 "+this.$t('台湾'), value: 14 },
        { text: "+95 "+this.$t('缅甸'), value: 15 },
        { text: "+960 "+this.$t('马尔代夫'), value: 16 }
      ]
    };
  },
  methods: {
    onClickLeft() {
      this.$router.replace("/main");
    },
    onClickRight() {
      this.$router.push("/main/public/language");
    },
    onChangeArea(value) {
      this.dropTitle = this.option1[value].text.split(" ")[0];
    },
    submitHandler(e) {
      const { mobile, password } = this;
      let field = {
        mobile,
        password
      };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some(key => {
        let validInfo = this.$validate[
          "valid" + key.charAt(0).toUpperCase() + key.slice(1)
        ](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000,
            position: "bottom"
          });
          return true;
        } else {
          return false;
        }
      });
      if (!objSome) {
        field.loginType = "password";
        this.$store
          .dispatch("login/login", field)
          .then(() => {
            if (this.remember) {
              saveToLocal("mobile", this.mobile);
              saveToLocal("password", this.password);
            }
            setTimeout(() => {
              this.$router.replace("/main");
            }, 1000);
          })
          .catch(() => {});
      }
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.loginView
  .van-nav-bar
    background: transparent
  .loginsignup2
    padding-bottom: 0
    padding-top: 0
    position: relative
    img
      width: 100%
  input
    height: 0.8rem
  .van-field__left-icon
    line-height: 0.8rem
    margin-right: 0.36rem
    .van-icon
      font-size: 0.54rem
      color: #4b34c3
  .login_box
    padding: 0.6rem 0 0.8rem
    background: #151d31;
    margin-top: 0.5rem;
    .mobile
      .van-field__label
        justify-content: center
        display: flex
        align-items: center
        width: auto
        .van-dropdown-menu
          .van-dropdown-menu__bar
            height: auto
            background: none
            .van-dropdown-menu__title
              padding: 0 0.21333rem 0 0
      .van-field__control
        margin-left: 0.15rem
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
    h2
      color: $grayTextColor
  .logo
    width: 100%
    margin: 0
    padding: 0
    height: auto
  .login_user_opreate
    display: -webkit-flex
    display: flex
    margin: 0.6rem 0 0 0.5rem
    color: #fff
    font-size: 0.36rem
  .login_user_opreate a
    flex: 1
    font-size: 0.38rem
    margin-left: 0.2rem
  .login_user_opreate a.register
    text-align: left
  .login_user_opreate a.forgot
    text-align: right
  .remember
    padding: 0.26667rem 0.42667rem
    .van-checkbox__icon
      font-size: 0.34rem
</style>
