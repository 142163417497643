<template>
  <div id="app">
    <transition name="custom-classes-transition" :enter-active-class="enterAnimate" :leave-active-class="leaveAnimate">
      <keep-alive :include="needAlive">
        <router-view class="Router animated"></router-view>
      </keep-alive>
    </transition>
    <!-- <service></service> -->
  </div>
</template>
<script>
import service from '@/components/service';
export default {
  components: {
    service,
  },
  data() {
    return {
      enterAnimate: '',
      leaveAnimate: '',
      needAlive: [],
    };
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {
    $route(to, from) {
      const toDepth = to.path.length;
      const fromDepth = from.path.length;
      //同一级页面无需设置过渡效果
      if (toDepth === fromDepth || (to.path == '/main/home' && from.path == '/')) {
        return;
      }
      this.enterAnimate = toDepth > fromDepth ? 'animated fadeInRight' : 'animated fadeInLeft';
      this.leaveAnimate = toDepth > fromDepth ? 'animated fadeOutLeft' : 'animated fadeOutRight';
    },
  },
};
</script>

<style lang="stylus">
*:not(input, textarea)
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
.van-notify
  text-align: left
#app
  font-family: 'Avenir', Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  .Router
    position: absolute
    left: 0
    right: 0
    height: 100%
  .animated
    animation-duration: 0.3s !important
</style>
