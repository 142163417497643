import request from '@/utils/request';
import Vue from 'vue';

export function checkToken(data) {
  return request({
    url: '/login/checkToken?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function login(data) {
  return request({
    url: '/login/index?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

export function reg(data) {
  return request({
    url: '/login/reg?lang=' + Vue.prototype.$lang,
    method: 'post',
    data: {
      data: Vue.prototype.$func.confusion(data),
    },
  });
}

export function forgot(data) {
  return request({
    url: '/login/forgot?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
export function getRegProtocol(data) {
  return request({
    url: '/login/getRegProtocol?lang=' + Vue.prototype.$lang,
    method: 'post',
    data,
  });
}
