<template>
  <div class="sericeView">
    <img src="@/assets/images/customer.png" width="55" @click="toService" />
    {{ $t('在线客服') }}
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    toService() {
      window.location.href =
        "#";
    }
  }
};
</script>
<style lang="stylus">
.sericeView
  position: fixed
  bottom: 1.75rem
  right: 0
  z-index: 99
  color: #999
  line-height: 1
  text-align: center
  font-size: 0.325rem
  img
    display: block
    margin: 0 auto
</style>
