function saveToLocal(key, value) {
  let mypakcet = window.localStorage.__mypakcet__;
  if (!mypakcet) {
    mypakcet = {};
  } else {
    mypakcet = JSON.parse(mypakcet);
  }
  mypakcet[key] = value;
  window.localStorage.__mypakcet__ = JSON.stringify(mypakcet);
}

function loadFromLocal(key, def) {
  let mypakcet = window.localStorage.__mypakcet__;
  if (!mypakcet) {
    return def;
  }
  mypakcet = JSON.parse(mypakcet);
  if (!mypakcet) {
    return def;
  }
  let result = mypakcet[key];
  return result || def;
}

export { saveToLocal, loadFromLocal };
