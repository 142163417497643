<template>
  <div class="mainView">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <van-tabbar v-model="active">
      <van-tabbar-item @click="toHome" icon="wap-home-o">{{ $t('首页') }}</van-tabbar-item>
      <van-tabbar-item @click="toTask" icon="sign">{{ $t('任务') }}</van-tabbar-item>
      <van-tabbar-item @click="toVip" icon="gem-o">{{ $t('VIP') }}</van-tabbar-item>
      <van-tabbar-item @click="toProfit" icon="balance-o">{{ $t('收益') }}</van-tabbar-item>
      <van-tabbar-item @click="toUser" icon="manager-o">{{ $t('我的') }}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      name: 'Main',
    };
  },
  created() {
    switch (this.$route.path) {
      case '/main/home':
        this.active = 0;
        break;
      case '/main/task':
        this.active = 1;
        break;
      case '/main/vip':
        this.active = 2;
        break;
      case '/main/profit':
        this.active = 3;
        break;
      case '/main/user':
        this.active = 4;
        break;
    }
  },
  methods: {
    toHome() {
      this.$router.replace('/main/home').catch(() => {});
    },
    toTask() {
      this.$router.replace('/main/task').catch(() => {});
    },
    toVip() {
      this.$router.replace('/main/vip').catch(() => {});
    },
    toProfit() {
      this.$router.replace('/main/profit').catch(() => {});
    },
    toUser() {
      this.$router.replace('/main/user').catch(() => {});
    },
  },
};
</script>
<style lang="stylus">
.mainView
  padding-bottom: 1.3rem
  .van-tabbar:after
    border: 0 !important
  .van-tabbar-item--active
    color: $specColor !important
    background: none
  .van-tabbar-item__icon .van-icon
    display: block
    font-size: 0.64rem
</style>
