//自定义公共js
let Base64 = require('js-base64').Base64;
import md5 from 'js-md5';
export default {
  formattedDate: function(timestamp) {
    console.log(timestamp)
    // 将时间戳转换为日期对象
    const date = new Date(timestamp * 1000); // PHP 时间戳是秒，JavaScript 时间戳是毫秒，因此乘以 1000

    // 格式化日期
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // 返回格式化的字符串
    return `${year}.${month}.${day}-${hours}:${minutes}:${seconds}`;
  },
  formatDate: function(date) {
    let seperator1 = '-';
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = '0' + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = '0' + strDate;
    }
    let currentdate = year + seperator1 + month + seperator1 + strDate;
    return currentdate;
  },
  /*
	指定长度和基数

*/
  uuid: function(len, radix) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    var uuid = [],
      i;
    radix = radix || chars.length;

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      // rfc4122, version 4 form
      var r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }

    return uuid.join('');
  },
  /**
   * @param {string} path
   * @returns {Boolean}
   */
  isExternal: function(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
  },
  /**参数说明：
   * 根据长度截取先使用字符串，超长部分追加…
   * str 对象字符串
   * len 目标字节长度
   * 返回值： 处理结果字符串
   */
  cutString: function(str, len) {
    //length属性读出来的汉字长度为1
    if (str.length * 2 <= len) {
      return str;
    }
    var strlen = 0;
    var s = '';
    for (var i = 0; i < str.length; i++) {
      s = s + str.charAt(i);

      if (str.charCodeAt(i) > 128) {
        strlen = strlen + 2;

        if (strlen >= len) {
          return s.substring(0, s.length - 1) + '...';
        }
      } else {
        strlen = strlen + 1;

        if (strlen >= len) {
          return s.substring(0, s.length - 2) + '...';
        }
      }
    }
    return s;
  },
  confusion: function(str, key) {
    var string = JSON.stringify(str);
    string = Base64.encode(string);
    return encodeURIComponent(this.msgEncrypt(string, 'ENCODE', key));
  },
  mysubstr: function(str, start, length) {
    if (length > 0) {
      return str.substr(start, length);
    } else {
      return str.substr(start, str.length - start);
    }
  },
  mytime: function() {
    return new Date().getTime() / 1000;
  },
  mybtoa: function(input) {
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    var str = String(input);
    for (
      // initialize result and counter
      var block, charCode, idx = 0, map = chars, output = '';
      // if the next str index does not exist:
      //   change the mapping table to "="
      //   check if d has no fractional digits
      str.charAt(idx | 0) || ((map = '='), idx % 1);
      // "8 - idx % 1 * 8" generates the sequence 2, 4, 6, 8
      output += map.charAt(63 & (block >> (8 - (idx % 1) * 8)))
    ) {
      charCode = str.charCodeAt((idx += 3 / 4));
      if (charCode > 0xff) {
        console.log('btoa  error');
      }
      block = (block << 8) | charCode;
    }
    return output;
  },
  str_replace: function(change, to, str) {
    return str.replace(change, to);
  },
  sizeof: function(str) {
    var totalLength = 0;
    var charCode;
    for (var i = 0; i < str.length; i++) {
      charCode = str.charCodeAt(i);
      if (charCode < 0x007f) {
        totalLength++;
      } else if (0x0080 <= charCode && charCode <= 0x07ff) {
        totalLength += 2;
      } else if (0x0800 <= charCode && charCode <= 0xffff) {
        totalLength += 3;
      } else {
        totalLength += 4;
      }
    }
    return totalLength;
  },
  msgEncrypt: function(string, operation = 'ENCODE', key = '', expiry = 0, string_length = 0) {
    var key_length = 4;
    key = md5(key != '' ? key : '69a3e8bd5b991e7b405a310537c55cd6');
    var fixedkey = md5.hex(key);
    var egiskeys = md5(this.mysubstr(fixedkey, 16, 16));
    var nowtime = this.mytime();
    var md5Nowtime = md5('' + nowtime + '');
    var runtokey = key_length ? (operation == 'ENCODE' ? this.mysubstr(md5Nowtime, md5Nowtime.length - key_length, key_length) : this.mysubstr(string, 0, key_length)) : '';

    var keys = md5(this.mysubstr(runtokey, 0, 16) + this.mysubstr(fixedkey, 0, 16) + this.mysubstr(runtokey, 16, null) + this.mysubstr(fixedkey, 16, null));
    string = operation == 'ENCODE' ? '0000000000' + this.mysubstr(md5(string + egiskeys), 0, 16) + string : base64.decode(this.mysubstr(string, key_length, null));
    var i = 0;
    result = '';
    if (string_length == 0) {
      string_length = this.sizeof(string);
    }

    for (i = 0; i < string_length; i++) {
      if (string[i] != undefined) {
        result += String.fromCharCode(string[i].charCodeAt() ^ keys[i % 32].charCodeAt());
      }
    }
    if (operation == 'ENCODE') {
      var result = runtokey + this.str_replace('=', '', this.mybtoa(result));
      return result;
    } else {
      if (parseInt(this.mysubstr(result, 0, 10)) == 0 || this.mysubstr(result, 0, 10) - new Date().getTime() > 0) {
        if (this.mysubstr(result, 10, 16) == this.mysubstr(md5(this.mysubstr(result, 26, string_length - 26) + egiskeys), 0, 16)) {
          return this.mysubstr(result, 26, string_length - 26);
        }
      }
      return '';
    }
  },
};
