/*
 * 全局权限检测
 * 包括1、路由的全局守卫
 */
import router from './router';
import store from './store';
import { saveToLocal, loadFromLocal } from '@/utils/local-storage';
import axios from 'axios';
import Vue from 'vue';

// 路由全局前置守卫
const whiteList = ['/login', '/register', '/forgot']; // 白名单
router.beforeEach((to, from, next) => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  var ismobile = flag ? 1 : 0;

  if (loadFromLocal('token', '')) {
    axios.defaults.headers = {
      access_token: loadFromLocal('token', ''),
    };
    if (to.path === '/login') {
      next();
    } else {
      //权限验证
      if (!whiteList.includes(to.path)) {
        store
          .dispatch('login/checkToken', {})
          .then((e) => {
            if (e.is_lock == 1) {
              saveToLocal('token', '');
              next('/login');
            }
          })
          .catch(() => {
            saveToLocal('token', '');
            next('/login');
          });
      }
      next();
    }
  } else {
    if (to.meta.checkAuth === false) {
      // 白名单，免密登录
      next();
    } else {
      // 否则就跳动登录页面
      next({ path: '/login', query: { redirect: to.fullPath } });
    }
  }
});
