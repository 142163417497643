import axios from 'axios';
import { Toast } from 'vant';
import store from '@/store';
import { loadFromLocal } from '@/utils/local-storage';

// create an axios instance
const service = axios.create({
  baseURL: 'https://cdn.kyotanjp.co/api', //process.env.VUE_APP_BASE_API, // url = base url + request url
  //baseURL: 'http://192.168.1.20:3333/api',
  //withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
  withCredentials: true,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = loadFromLocal('token', '');
    }
    return config;
  },
  (error) => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data;

    // if the custom code is not 10000, it is judged as an error.
    if (res.code !== 10000) {
      if (res.tips == true) {
        Toast({
          message: res.msg || 'Error',
          duration: 1000,
          forbidClick: true,
          position: 'bottom',
        });
      }
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
      //   // to re-login
      //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      //     confirmButtonText: 'Re-Login',
      //     cancelButtonText: 'Cancel',
      //     type: 'warning'
      //   }).then(() => {
      //     store.dispatch('user/resetToken').then(() => {
      //       location.reload();
      //     });
      //   });
      // }
      return Promise.reject(res);
    } else {
      return res;
    }
  },
  (error) => {
    //console.log('err' + error); // for debug
    Toast({
      message: error.message,
      duration: 1000,
      forbidClick: true,
      position: 'bottom',
    });
    return Promise.reject(error);
  }
);

export default service;
