import { Toast } from 'vant';
const state = {};

const mutations = {};

const actions = {
  // 发送短信验证码
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
