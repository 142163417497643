import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/login/index.vue';
import Main from '@/views/main.vue';
Vue.use(VueRouter);

const routes = [
  //登录
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      checkAuth: false,
    },
  },
  //注册
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/login/register.vue'),
    meta: {
      checkAuth: false,
    },
  },
  //搜索
  {
    path: '/main/public/search',
    name: 'Search',
    component: () => import('@/views/public/search.vue'),
    meta: {
      checkAuth: false,
    },
  },
    //购买记录
    {
      path: '/main/user/profit_lists',
      name: 'Lc',
      component: () => import('@/views/user/profit_lists.vue'),
      meta: {
        checkAuth: false,
      },
    }, 
  //收益宝
  {
    path: '/main/user/lc',
    name: 'Lc',
    component: () => import('@/views/user/lc.vue'),
    meta: {
      checkAuth: false,
    },
  },  
  //设置
  {
    path: '/main/user/setting',
    name: 'Setting',
    component: () => import('@/views/user/setting.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //多语言设置
  {
    path: '/main/public/language',
    name: 'Language',
    component: () => import('@/views/public/language.vue'),
    meta: {
      checkAuth: false,
    },
  },
  //任务列表
  {
    path: '/main/tasklist/list',
    name: 'TasklistList',
    component: () => import('@/views/task/list.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //查看任务
  {
    path: '/main/home/taskShow/:id/:task_id',
    name: 'TaskShow',
    component: () => import('@/views/task/show.vue'),
    meta: {
      checkAuth: true,
    },
  },


  //日结报表
  {
    path: '/main/user/dayReport',
    name: 'DayReport',
    component: () => import('@/views/user/dayReport.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //团队报表
  {
    path: '/main/user/teamReport',
    name: 'TeamReport',
    component: () => import('@/views/user/teamReport.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //绑定账号
  {
    path: '/main/user/blindAccount',
    name: 'BlindAccount',
    component: () => import('@/views/user/blindAccount.vue'),
    meta: {
      checkAuth: true,
    },
  },

  //已完成订单
  {
    path: '/main/user/orderlist',
    name: 'OrderList',
    component: () => import('@/views/user/order_list.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //我的商品
  {
    path: '/main/user/mygoods',
    name: 'MyGoods',
    component: () => import('@/views/user/my_goods.vue'),
    meta: {
      checkAuth: true,
    },
  },

  //我的预约记录
  {
    path: '/main/user/my_appointment_goods',
    name: 'MyAppointmentGoods',
    component: () => import('@/views/user/my_appointment_goods.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //资金明细
  {
    path: '/main/user/money_log',
    name: 'MoneyLog',
    component: () => import('@/views/user/MoneyLog.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //信用明细
  {
    path: '/main/user/creditDetail',
    name: 'CreditDetail',
    component: () => import('@/views/user/creditDetail.vue'),
    meta: {
      checkAuth: true,
    },
  },

  //个人资料
  {
    path: '/main/user/userinfo',
    name: 'UserInfo',
    component: () => import('@/views/user/userinfo.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //团队管理
  {
    path: '/main/user/team/:id?',
    name: 'Team',
    component: () => import('@/views/user/team.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //收款方式
  {
    path: '/main/user/collections',
    name: 'Collections',
    component: () => import('@/views/user/collections.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //添加修改收款
  {
    path: '/main/user/addCollections',
    name: 'AddCollections',
    component: () => import('@/views/user/addCollections.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //帮助中心
  {
    path: '/main/user/help',
    name: 'Help',
    component: () => import('@/views/user/help.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //转账
  {
    path: '/main/user/transfer',
    name: 'TransFer',
    component: () => import('@/views/user/transfer.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //兑换
  {
    path: '/main/user/exchange',
    name: 'Exchange',
    component: () => import('@/views/user/exchange.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //钱包
  {
    path: '/main/user/wallet',
    name: 'Wallet',
    component: () => import('@/views/user/wallet.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //分享注册
  {
    path: '/main/user/invite',
    name: 'Invite',
    component: () => import('@/views/user/invite.vue'),
    meta: {
      checkAuth: true,
    },
  },
  {
    path: '/main/user/credit',
    name: 'Credit',
    component: () => import('@/views/user/credit.vue'),
    meta: {
      checkAuth: true,
    },
  },
  //留言反馈
  {
    path: '/main/user/add_message',
    name: 'AddMessage',
    component: () => import('@/views/user/add_message.vue'),
    meta: {
      keepAlive: false,
    },
  },
  //留言列表
  {
    path: '/main/user/add_message/message_lists',
    name: 'MessageLists',
    component: () => import('@/views/user/message_lists.vue'),
    meta: {
      keepAlive: false,
    },
  },
  //充值
  {
    path: '/main/user/recharge',
    name: 'Recharge',
    component: () => import('@/views/user/recharge.vue'),
    meta: {
      keepAlive: false,
    },
  },
  //充值列表
  {
    path: '/main/user/recharge/recharge_lists',
    name: 'RechargeLists',
    component: () => import('@/views/user/recharge_lists.vue'),
    meta: {
      keepAlive: false,
    },
  },
  //客服
  {
    path: '/main/user/add_message/customer',
    name: 'Customer',
    component: () => import('@/views/user/customer.vue'),
    meta: {
      keepAlive: false,
    },
  },

  //审核任务
  {
    path: '/main/user/auditRecord',
    name: 'AuditRecord',
    component: () => import('@/views/user/auditRecord.vue'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/',
    component: Main,
    redirect: '/main/home',
    children: [
      {
        path: 'main',
        name: 'Main',
        redirect: '/main/home',
        component: () => import('@/views/main.vue'),
        meta: { title: '首页' },
        children: [
          //首页
          {
            path: 'home',
            name: 'Home',
            component: () => import('@/views/home/index.vue'),
            meta: {
              checkAuth: false,
            },
          },
          //分类
          {
            path: 'task/:id',
            name: 'Task',
            component: () => import('@/views/task/index.vue'),
            meta: {
              checkAuth: true,
            },
          },
          {
            path: 'task',
            redirect: '/main/task/0', 
          },
          //分类
          {
            path: 'tasklist',
            name: 'TaskList',
            component: () => import('@/views/home/task.vue'),
            meta: {
              checkAuth: true,
            },
          },
          //vip
          {
            path: 'vip',
            name: 'Vip',
            component: () => import('@/views/vip/index.vue'),
            meta: {
              checkAuth: true,
            },
          },
          //收益
          {
            path: '/main/profit',
            name: 'Profit',
            component: () => import('@/views/home/profit.vue'),
            meta: {
              checkAuth: true,
            },
          },
          //我的
          {
            path: 'user',
            name: 'User',
            component: () => import('@/views/user/index.vue'),
            meta: {
              checkAuth: true,
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
