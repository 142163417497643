//自定义公共js
export default {
  //号段区域验证
  validArea: function(area) {
    if (area == '') {
      return { status: false, msg: '请选择手机号码区号' };
    }
    return { status: true };
  },
  //手机验证
  validMobile: function(mobile) {
    if (mobile == '') {
      return { status: false, msg: '请输入手机号码' };
    }
    return { status: true };
  },
  //旧手机验证
  validOldmobile: function(oldmobile, field) {
    if (oldmobile == '') {
      return { status: false, msg: '请输入原手机号码' };
    }

    if (field.mobile == oldmobile) {
      return { status: false, msg: '新号码不能与原手机相同' };
    }
    return { status: true };
  },
  //昵称验证
  validNickname: function(nickname) {
    if (nickname == '') {
      return { status: false, msg: '请输入昵称' };
    }
    return { status: true };
  },
  //登录密码验证
  validPassword: function(password) {
    if (password == '') {
      return { status: false, msg: '请输入登录密码' };
    }
    const re = /^[A-Za-z0-9]{6,20}$/;
    if (!re.test(password)) {
      return { status: false, msg: '登录密码6-20位英文或数字' };
    }
    return { status: true };
  },
  //原密码验证
  validOldpassword: function(oldpassword) {
    if (oldpassword == '') {
      return { status: false, msg: '请输入原登录密码' };
    }
    const re = /^[A-Za-z0-9]{6,20}$/;
    if (!re.test(oldpassword)) {
      return { status: false, msg: '原密码应为6-20位英文或数字' };
    }
    return { status: true };
  },
  //确认登录密码验证
  validRepassword: function(repassword, field) {
    if (repassword == '') {
      return { status: false, msg: '请输入确认登录密码' };
    }
    const re = /^[A-Za-z0-9]{6,20}$/;
    if (!re.test(repassword)) {
      return { status: false, msg: '确认密码6-20位英文或数字' };
    }
    if (field.password != repassword) {
      return { status: false, msg: '两次输入的密码不一致' };
    }
    return { status: true };
  },
  //原交易密码验证
  validOldpaypwd: function(oldpaypwd) {
    if (oldpaypwd == '') {
      return { status: false, msg: '请输入原资金密码' };
    }
    const re = /^[A-Za-z0-9]{6,20}$/;
    if (!re.test(oldpaypwd)) {
      return { status: false, msg: '原资金密码6-20位英文或数字' };
    }
    return { status: true };
  },
  //交易密码验证
  validPaypwd: function(paypwd) {
    if (paypwd == '') {
      return { status: false, msg: '请输入资金密码' };
    }
    const re = /^[A-Za-z0-9]{6,20}$/;
    if (!re.test(paypwd)) {
      return { status: false, msg: '资金密码6-20位英文或数字' };
    }
    return { status: true };
  },
  //确认交易密码验证
  validRepaypwd: function(repaypwd, field) {
    if (repaypwd == '') {
      return { status: false, msg: '请输入确认资金密码' };
    }
    const re = /^[A-Za-z0-9]{6,20}$/;
    if (!re.test(repaypwd)) {
      return { status: false, msg: '确认资金密码6-20位英文或数字' };
    }
    if (field.paypwd != repaypwd) {
      return { status: false, msg: '两次输入的资金密码不一致' };
    }
    return { status: true };
  },
  //推荐验证
  validInviteParent: function(inviteParent) {
    if (inviteParent == '' || inviteParent == undefined) {
      return { status: false, msg: '请输入邀请码' };
    }
    const re = /^[a-zA-Z\d]+$/;
    if (!re.test(inviteParent)) {
      return { status: false, msg: '邀请码格式不正确' };
    }
    return { status: true };
  },
  //验证码校验
  validVerifyCode: function(verifyCode) {
    if (verifyCode == '') {
      return { status: false, msg: '请输入验证码' };
    }
    const re = /^[A-Za-z0-9]{4}$/;
    if (!re.test(verifyCode)) {
      return { status: false, msg: '验证码长度不符' };
    }
    return { status: true };
  },
  //货币类型
  validMoney_type: function(money_type) {
    if (money_type == '') {
      return { status: false, msg: '请选择货币类型' };
    }
    const re = /^[0-9]*$/;
    if (!re.test(money_type)) {
      return { status: false, msg: '货币类型格式有误' };
    }
    return { status: true };
  },
  //收款方式
  validUser_gathering_id: function(user_gathering_id) {
    if (user_gathering_id == '') {
      return { status: false, msg: '请选择收款方式' };
    }
    const re = /^[0-9]*$/;
    if (!re.test(user_gathering_id)) {
      return { status: false, msg: '收款方式不正确' };
    }
    return { status: true };
  },
  getByteLen: function(val) {
    var len = 0;
    for (var i = 0; i < val.length; i++) {
      var a = val.charAt(i);
      if (a.match(/[^\x00-\xff]/gi) != null) {
        len += 1;
      } else {
        len += 1;
      }
    }
    return len;
  },
  random: function(lower, upper) {
    return Math.floor(Math.random() * (upper - lower + 1)) + lower;
  },
  //姓名验证
  validRealname: function(realname) {
    if (realname == '') {
      return { status: false, msg: '请输入您的姓名' };
    }
    return { status: true };
  },
  //银行卡卡号验证
  validAccount: function(account) {
    if (account == '') {
      return { status: false, msg: '请输入银行卡号' };
    }
    return { status: true };
  },
  //银行卡开户银行
  validBank_name: function(bank_name) {
    if (bank_name == '') {
      return { status: false, msg: '请输入银行名称' };
    }
    return { status: true };
  },
  //银行卡支行
  validSub_bank_name: function(sub_bank_name) {
    if (sub_bank_name == '') {
      return { status: false, msg: '请输入分行名称' };
    }
    return { status: true };
  },
    //片假名
    validRealname2: function(realname2) {
      if (realname2 == '') {
        return { status: false, msg: '请输入片假名' };
      }
      return { status: true };
    },
    //片假名
    validSub_bank_name2: function(sub_bank_name2) {
      if (sub_bank_name2 == '') {
        return { status: false, msg: '请输入分行编号' };
      }
      return { status: true };
    },
  //ID校验
  validId: function(id) {
    if (id == '') {
      return { status: false, msg: 'ID不存在' };
    }
    return { status: true };
  },
  //货币校验
  validMoney: function(money) {
    if (money == '') {
      return { status: false, msg: '请填写转账金额' };
    }
    const re = /^[1-9]\d*$/;
    if (!re.test(money)) {
      return { status: false, msg: '请填写正确的转账金额' };
    }
    return { status: true };
  },
  //提现金额校验
  validWithdraw_money: function(withdraw_money) {
    if (withdraw_money == '') {
      return { status: false, msg: '请填写提现金额' };
    }
    const re = /^[1-9]\d*$/;
    if (!re.test(withdraw_money)) {
      return { status: false, msg: '请填写正确的提现金额' };
    }
    return { status: true };
  },

  //转出账户验证
  validTo_mobile: function(to_mobile) {
    if (to_mobile == '') {
      return { status: false, msg: '请输入对方账号' };
    }

    return { status: true };
  },
  //提现方式校验
  validPaytype: function(paytype) {
    if (paytype.length == 0) {
      return { status: false, msg: '请选择提现方式' };
    }
    return { status: true };
  },
  //留言标题验证
  validTitle: function(title) {
    if (title == '') {
      return { status: false, msg: '请输入留言标题' };
    }
    return { status: true };
  }, //留言内容验证
  validContent: function(content) {
    if (content == '') {
      return { status: false, msg: '请输入留言内容' };
    }
    if (this.getByteLen(content) < 6) {
      return { status: false, msg: '留言内容过短' };
    }

    return { status: true };
  },
  //收款方式
  validType: function(type) {
    if (type == '') {
      return { status: false, msg: '请选择收款方式' };
    }
    return { status: true };
  },
};
