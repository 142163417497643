import { reg, forgot, login, checkToken } from '@/api/login';
import { saveToLocal, loadFromLocal } from '@/utils/local-storage';
import { Toast } from 'vant';
const state = {
  token: loadFromLocal('token', ''),
  name: '',
  avatar: '',
  introduction: '',
  userinfo: loadFromLocal('userinfo', ''),
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, userinfo) => {
    state.userinfo = userinfo;
  },
};

const actions = {
  // user checkToken
  checkToken({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      checkToken(userInfo)
        .then((response) => {
          const { result } = response;
          commit('SET_USERINFO', result);
          saveToLocal('userinfo', result);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      login(userInfo)
        .then((response) => {
          const { result } = response;
          commit('SET_TOKEN', result.access_token);
          saveToLocal('token', result.access_token);
          Toast({
            message: response.msg,
            duration: 1000,
            forbidClick: true,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user reg
  reg({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      reg(userInfo)
        .then((response) => {
          Toast({
            message: response.msg,
            duration: 1000,
            forbidClick: true,
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user login
  forgot({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      forgot(userInfo)
        .then((response) => {
          Toast({
            message: response.msg,
            duration: 1000,
            forbidClick: true,
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
